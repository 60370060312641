<template>
    <div class="card">
        <b class="uppercase">Hub Information</b>
        <hr class="card-divider" />
        <div class="mt-1">
            <img class="map-placeholder" src="@/../public/assets/map-main-placeholder.jpg"/>
        </div>
        <div class="ml-1 mt-2">
            <b>Contacts: </b>
            <div class="ml-2" v-for="phone of lContacts" :key="phone">
                <b>{{ phone.name }} </b> {{ phone.telephone_number }}
            </div>
        </div>
        <div class="ml-1 mt-2">
            <b>Address: </b> {{ hubDetail.address }}
        </div>
        <div class="ml-1 mt-2">
            <b>Distance from your Hub: </b> ?
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Your Stock',
    props: {
        hubDetail: { type: Object, required: true, default: null},
        lContacts: { type: Array, required: true, default: () => []},
    }
})
</script>

<style scoped>
.map-placeholder {
    width: 100%;
    height: 22vh;
    min-height: 150px;
    border: 2px solid #9cc7ec;
    text-align: center;
}
</style>