
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import LocationDetail from './components/LocationDetail.vue';

export default defineComponent({
  name: 'Locations',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon,
    LocationDetail
  },
  computed: {
    hubs() {
      return this.$store.state.userDetail?.hubs.map((el: any) => { return el.id })
    }
  },
  data() {
    return {
        filteredList: [] as any[],
        locations: [] as any[],
        filter: '',
        selection: null
    }
  },
  ionViewDidEnter() {
    this.getLocations()
  },
  ionViewDidLeave() {
    this.resetAllVariables()
  },
  methods: {
    async getLocations() {
      const uniquekey = 'cache:wma:locations'
      const cached = localStorage.getItem(uniquekey)
      if (cached) {
        const parsed = JSON.parse(cached)
        this.locations = parsed.filter((el: any) => { return !this.hubs.includes(el.id) })
      }

      if (navigator.onLine) {
          try {
              const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/locations'))
              localStorage.setItem(uniquekey, JSON.stringify(resp.data))
              this.locations = resp.data.filter((el: any) => { return !this.hubs.includes(el.id) })
            this.filterLocations()
          } catch (err) { 
              console.log(err)
          }
      }

      this.filterLocations()
    },
    filterLocations() {
        this.filteredList.splice(0, this.filteredList.length)
        
        this.filteredList = this.locations.filter( (item: any) => {
            return item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 || item.address.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
        })
    },
    select(location: any) {
        this.selection = location
        setTimeout(() => this.scrollToTop(), 500)
    },
    scrollToTop() {
      const elem = document.getElementById('location-scroller') as any
      elem.scrollToTop()
    },
    resetAllVariables() {
        this.filteredList = [] as any[]
        this.locations = [] as any[]
        this.filter = ''
        this.selection = null
    }
  }
});
