
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Price List',
    props: {
        hub: { type: Number, required: true, default: 0 },
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            filteredList: [] as any[],
            prices: [] as any[],
            unauthorized: false
        }
    },
    mounted() {
        this.loadPrices()
    },
    methods: {
        async loadPrices() {
            const uniquekey = 'cache:wma:pricing-' + this.$props.hub
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.prices =  parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/pricing/', this.$props.hub.toString()))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.prices = resp.data
                } catch (err) { 
                    console.log(err)
                    this.unauthorized = err.response.status == 401
                }
            }

            this.filteredList = this.prices.slice()
        },
        parseDate(date: string) {
            return (new Date(date)).toLocaleDateString()
        },
        resetAllData() {
            this.filteredList = []
            this.prices = []
            this.unauthorized = false
        }
    },
    watch: {
        hub: {
            handler: function() {
                this.resetAllData()
                this.loadPrices()
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadPrices()
                }
            }
        }
    }
})
