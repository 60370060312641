
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Your Stock',
    props: {
        hubDetail: { type: Object, required: true, default: null},
        lContacts: { type: Array, required: true, default: () => []},
    }
})
