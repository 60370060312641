<template>
  <ion-page>
    <ion-content id="location-scroller">
      <ion-grid>
        <ion-row class="mt-5">
            <ion-col size="0.2"></ion-col>
            <ion-col size="2" class="ion-hide-md-down" style="min-width: 290px">
              <ion-row>
                <b>Locations</b>
              </ion-row>

              <ion-row class="ml-1" style="min-width: 290px">
                <div class="mt-2" style="display: flex;">
                    <ion-icon name="search"></ion-icon>
                    <input class="searchbar searchbar-secondary" type="text" placeholder="Search by name or address" v-model="filter" @input="filterLocations" />
                </div>
              </ion-row>

              <ion-row class="side-location-menu side-line ml-1">
                <div style="display: inline-table; width: 12vw; min-width: 200px">
                    <div class="card clickable mt-2 card-shadow"  @click="select(l)" v-for="l of filteredList" :key="l">
                      <b>{{ l.name }}</b>
                      <div class="ml-1">
                          <div class="mt-2">{{ l.locationtype.name }}</div>
                          <div class="mt-2">{{ l.address }}</div>
                          <div class="mt-2">{{ l.telephone_number }}</div>
                      </div>
                    </div>
                </div>
              </ion-row>
            </ion-col>


            <ion-col >
              <!-- mobile only -->
              <div class="side-location-menu-cmp">
                <label class="bold-text">Location</label>
                <select class="searchbar" style="margin-left: auto; margin-right: 2%; text-align-last: end" name="selloc" id="selloc" @change="select(selection)" v-model="selection">
                    <option v-for="l of filteredList" :key="l" :value="l">{{ l.name }} ({{ l.address }})</option>
                </select>
              </div>
                <div v-if="selection">
                    <location-detail :hub="selection"></location-detail>
                </div>
                <div style="margin-top: 10vh;" v-else>Select a location to see details.</div>
            </ion-col>
            <ion-col size="0.4"></ion-col>
          </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import LocationDetail from './components/LocationDetail.vue';

export default defineComponent({
  name: 'Locations',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow, IonIcon,
    LocationDetail
  },
  computed: {
    hubs() {
      return this.$store.state.userDetail?.hubs.map((el: any) => { return el.id })
    }
  },
  data() {
    return {
        filteredList: [] as any[],
        locations: [] as any[],
        filter: '',
        selection: null
    }
  },
  ionViewDidEnter() {
    this.getLocations()
  },
  ionViewDidLeave() {
    this.resetAllVariables()
  },
  methods: {
    async getLocations() {
      const uniquekey = 'cache:wma:locations'
      const cached = localStorage.getItem(uniquekey)
      if (cached) {
        const parsed = JSON.parse(cached)
        this.locations = parsed.filter((el: any) => { return !this.hubs.includes(el.id) })
      }

      if (navigator.onLine) {
          try {
              const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/locations'))
              localStorage.setItem(uniquekey, JSON.stringify(resp.data))
              this.locations = resp.data.filter((el: any) => { return !this.hubs.includes(el.id) })
            this.filterLocations()
          } catch (err) { 
              console.log(err)
          }
      }

      this.filterLocations()
    },
    filterLocations() {
        this.filteredList.splice(0, this.filteredList.length)
        
        this.filteredList = this.locations.filter( (item: any) => {
            return item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 || item.address.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
        })
    },
    select(location: any) {
        this.selection = location
        setTimeout(() => this.scrollToTop(), 500)
    },
    scrollToTop() {
      const elem = document.getElementById('location-scroller') as any
      elem.scrollToTop()
    },
    resetAllVariables() {
        this.filteredList = [] as any[]
        this.locations = [] as any[]
        this.filter = ''
        this.selection = null
    }
  }
});
</script>

<style scoped>
.side-line {
  border-right: 2px solid #81b9ee;
  margin-right: 0.5vw;
  margin-top: 2vh;
}

.card:first-child {
  margin-top: 0;
}

.overflow {
    height: 80vh;
    overflow-y: auto;
}

.overflow-main {
  height: 89vh;
  overflow-y: auto;
}

.card-shadow {
  box-shadow: #bfbfbf 1px 6px 5px 0px;
}

.side-location-menu {
  display: none;
}

.side-location-menu-cmp {
  display: flex;
  background: white;
  padding: 5px 10px 0px 10px;
  border-radius: 12px;
  box-shadow: 0px 3px 3px lightgrey;
  width: 70vw;
  min-height: 30px;
  align-items: center;
  margin-bottom: 5vh;
}

@media screen and (min-width: 710px) {
  .side-location-menu {
    display: block;
  }

  .side-location-menu-cmp {
    display: none;
  }
}

</style>
