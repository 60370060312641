
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import HubInfo from './otherLocation/HubInfo.vue';
import Stock from './otherLocation/Stock.vue';
import Transactions from './otherLocation/Transactions.vue';
import PriceList from './otherLocation/PriceList.vue';
import Employees from './otherLocation/Employees.vue';

export default defineComponent({
    name: 'Location Detail',
    props: {
        hub: { type: Object, required: true, default: null},
        destroy: { type: Boolean, default: false }
    },
    components: {
        IonGrid, IonCol, IonRow,
        HubInfo, Stock, Transactions, PriceList, Employees
    },
    data() {
        return {
            filter: '',
            dtUpdate: '-',
            contacts: [] as any[]
        }
    },
    computed: {
        getTitle(): string {
            return ''.concat(this.$props.hub.name, ' - ', this.$props.hub.locationtype.name)
        }
    },
    mounted() {
        this.loadContacts()
    },
    methods: {
        async loadContacts() {
            const uniquekey = 'cache:wma:location-' + this.$props.hub.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.contacts = parsed.res
                this.dtUpdate = parsed.timestamp
            }

            if (navigator.onLine) {
                try {
                    this.dtUpdate = (new Date()).toLocaleString()
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/contacts/', this.$props.hub.id.toString()))
                    localStorage.setItem(uniquekey, JSON.stringify({'res': resp.data, 'timestamp': this.dtUpdate}))
                    this.contacts = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        resetAllData() {
            this.filter = ''
            this.contacts = []
        }
    },
    watch: {
        hub: {
            handler: function() {
                this.loadContacts()
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadContacts()
                }
            }
        }
    }
})
