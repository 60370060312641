
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Transactions',
    props: {
        hub: { type: Number, required: true, default: 0 },
        destroy: { type: Boolean, default: false }
    },
    components: { IonIcon },
    data() {
        return {
            pending: [] as any[],
            transactions: [] as any[],
            total_count: 0,
            max_range: 15,
            page: 0,
            unauthorized: false,
            convRate: {} as any,
            loaded: false
        }
    },
    mounted() {
        this.loadTransactions()

        this.loadConversionRate()
    },
    methods: {
        async loadConversionRate() {
            const uniquekey = 'cache:wma:conversion-rate'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.convRate = parsed[0]
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], "/conversion_rate/"))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.convRate = resp.data[0]
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        async loadTransactions(start?: number, end?: number) {
            const payload = {
                location: this.$props.hub.toString(),
                start_idx: start || 0,
                end_idx: end || this.max_range
            }

            const uniquekey = 'cache:wma:transactions-' + payload.location + '_' + payload.start_idx + '-' + payload.end_idx
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.pending = parsed.pending || []
                this.transactions = parsed.transactions || []
                this.total_count = parsed.total_count || 0
                this.loaded = true
            } else {
                this.pending = []
                this.transactions = []
                this.loaded = true
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions/financial_waste_list/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.pending = resp.data.pending || []
                    this.transactions = resp.data.transactions || []
                    this.total_count = resp.data.total_count || 0
                    this.loaded = true
                } catch (err) { 
                    console.log(err)
                    this.unauthorized = err.response.status == 401
                }
            }
        },
        getSubject(transaction: any) {
            if (transaction.in_user_data) {
            return ''.concat(transaction.in_user_data?.user_data?.username)
            }

            if (transaction.out_user_data) {
            return ''.concat(transaction.out_user_data?.user_data?.username)
            }

            if (transaction.in_location_data?.id != this.$props.hub) {
            return transaction.in_location_data?.name
            }

            if (transaction.out_location_data?.id != this.$props.hub) {
            return transaction.out_location_data?.name
            }
            return '-'
        },
        getStatus(item: any) {
            if (item.is_cancelled) {
                return 'Rejected'
            }

            if (item.is_executed) {
                return 'Confirmed'
            } else {
                return 'Pending'
            }
        },
        getTransportStatus(item: any) {
            if (!item.in_location_data || !item.out_location_data) {
                return '-'
            }

            if (item.is_executed) {
                return 'Delivered'
            }

            if (item.is_cancelled) {
                return 'Cancelled'
            }

            if (item.starting_date) {
                const dt = new Date(item.starting_date)
                return 'Shipped (' + dt.toLocaleDateString() + ')'
            } else {
                return 'Shipping Soon'
            }
        },
        parseDate(item: any) {
            return (new Date(item.execution_date || item.insertion_date)).toLocaleString()
        },
        computeSign(transaction: any, pending=false) {
            if (pending) {
                if (transaction?.in_location_data?.id == this.$props.hub) {
                    return transaction?.value
                } else {
                    return -1 * transaction?.value
                }
            }

            if (transaction.transaction_data?.in_location_data?.id == this.$props.hub) {
                return -1 * transaction.transaction_data?.value
            } else {
                return transaction.transaction_data?.value
            }
        },
        getRangePageInfo() {
            const start = this.getStartIdx
            const end = this.getEndIdx
            return ''.concat(start.toString(), ' - ', end.toString())
        },
        isLastPage() {
            return this.page == Math.floor(this.total_count / this.max_range)
        },
        changePage(isBack: boolean) {
            if (isBack) {
                this.page--
            } else {
                this.page++
            }
            this.loadTransactions(this.getStartIdx - 1, this.getEndIdx)
        },
        resetAllData() {
            this.pending = []
            this.transactions = []
            this.total_count = 0
            this.max_range = 15
            this.page = 0
            this.unauthorized = false
            this.loaded = false
        }
    },
    computed: {
        getStartIdx(): any {
            return Math.min((this.page * this.max_range) + 1, this.getEndIdx)
        },
        getEndIdx(): any {
            return Math.min((this.page + 1) * this.max_range, this.total_count)
        },
    },
    watch: {
        hub: {
            handler: function() {
                this.resetAllData()
                this.loadTransactions()
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadTransactions()
                }
            }
        }
    }
})
