<template>
    <ion-grid>
        <ion-row>
            <b>Dashboard {{ getTitle }}</b>
            <label class="ml-2">Last Update: {{ dtUpdate }}</label>
        </ion-row>
        <ion-row class="mt-2">
            <ion-col size-xl="4.5" size-xs="12">
                <hub-info :hubDetail="hub" :lContacts="contacts"></hub-info>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="0.5"></ion-col>
            <ion-col size-xl="7" size-xs="12">
                <stock :hub="hub?.id"></stock>
            </ion-col>
        </ion-row>
        <ion-row class="mt-2 ion-hide-md-down">
            <ion-col size="12">
                <transactions :hub="hub.id"></transactions>
            </ion-col>
        </ion-row>
        <ion-row class="mt-2">
            <ion-col size-xl="7" size-xs="12">
                <price-list :hub="hub.id"></price-list>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="0.5"></ion-col>
            <ion-col size-xl="4.5" size-xs="12">
                <employees :hub="hub.id"></employees>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts">
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import HubInfo from './otherLocation/HubInfo.vue';
import Stock from './otherLocation/Stock.vue';
import Transactions from './otherLocation/Transactions.vue';
import PriceList from './otherLocation/PriceList.vue';
import Employees from './otherLocation/Employees.vue';

export default defineComponent({
    name: 'Location Detail',
    props: {
        hub: { type: Object, required: true, default: null},
        destroy: { type: Boolean, default: false }
    },
    components: {
        IonGrid, IonCol, IonRow,
        HubInfo, Stock, Transactions, PriceList, Employees
    },
    data() {
        return {
            filter: '',
            dtUpdate: '-',
            contacts: [] as any[]
        }
    },
    computed: {
        getTitle(): string {
            return ''.concat(this.$props.hub.name, ' - ', this.$props.hub.locationtype.name)
        }
    },
    mounted() {
        this.loadContacts()
    },
    methods: {
        async loadContacts() {
            const uniquekey = 'cache:wma:location-' + this.$props.hub.id
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.contacts = parsed.res
                this.dtUpdate = parsed.timestamp
            }

            if (navigator.onLine) {
                try {
                    this.dtUpdate = (new Date()).toLocaleString()
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/contacts/', this.$props.hub.id.toString()))
                    localStorage.setItem(uniquekey, JSON.stringify({'res': resp.data, 'timestamp': this.dtUpdate}))
                    this.contacts = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        resetAllData() {
            this.filter = ''
            this.contacts = []
        }
    },
    watch: {
        hub: {
            handler: function() {
                this.loadContacts()
            }
        },
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadContacts()
                }
            }
        }
    }
})
</script>

<style scoped>

</style>